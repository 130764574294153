import React from "react"

import { Row, Col, Container} from "react-bootstrap"




const StatsScreen = ({ match }) => {
  
  
  
  
       
  return (
    
    <> 
       
       <Container style={{background:"#DADAE0"}}>

       <Row className="center">
         <Col >
           <br/>
           {/* <p className="mCenter" style={{ fontSize: 22}}>
           Some statistics</p> */}
           <iframe allowtransparency frameborder='0' height='160' scrolling='no' src='https://www.strava.com/clubs/836635/latest-rides/7b66340da96ba066c080846f745ff7c4f4da66f5?show_rides=false' width='300'></iframe>
           <br/>
           <br/>
           <iframe allowtransparency frameborder='0' height='550' scrolling='no' src='https://www.strava.com/clubs/836635/latest-rides/7b66340da96ba066c080846f745ff7c4f4da66f5?show_rides=true' width='300'></iframe>
           
         </Col>
       </Row>
        
      <br/>
      <br/>
      <br/>
      </Container>
      
    </>
  )
}

export default StatsScreen
import React, { useEffect, useRef, useState } from "react"
import Papa from "papaparse";
import {Card, Image, Col, Row, Alert, Badge, Container, ProgressBar, Toast} from "react-bootstrap"
import Chart from "react-apexcharts"
import useGoogleSheets from 'use-google-sheets';
import { GiRunningShoe,  GiBrain, GiPathDistance, GiSpeedometer, GiMedicines, GiNightSleep, GiBed, GiWalk, GiFlame, GiCalendar, GiWineGlass, GiStrong, GiWaterBottle, GiCoffeeCup} from "react-icons/gi";

var seriesTime = [];
var seriesSteps = [];
var seriesCalories = [];
var seriesFloors = [];
var seriesSleep = [];
var seriesSleepScore = [];
var seriesStress = [];
var seriesWorkouts = [];
var seriesMigreneScore = [];
var seriesMigreneMed = [];
var seriesAlcohol = [];
var seriesCoffee = [];
var seriesWater = [];
var seriesWorkouts = [];
var seriesRuns = [];
var seriesWeight = [];
var seriesBedtime = [];
var bedtime_raw = [];
var seriesWeekly = [];



var AverageSteps;
var AverageSleep;
var AverageStime;
var AverageStress;
var AverageCalories;
var AverageAlco;
var AverageWorkout;
var AverageMigrene;
var AverageWater;
var AverageCoffee;
var AverageFree;
var AverageActive;
var AverageMed;
var DayOfYear;
var SleepMin;
var Streak;


var SheetData = [];
var StepsSeries = {};
var StepsOptions = {};
var SleepSeries = {};
var SleepOptions = {};
var MigreneSeries = {};
var MigreneOptions = {};
var DrinkSeries = {};
var DrinkOptions = {};
var RunSeries = {};
var RunOptions = {};
var SleepTimeOptions = {};
var SleepTimeSeries = {};

var GarminData = [];
var AverageData = [];



const Graphs = () => {

const [isReady, setReady] = useState(false); // Loading state


  function getCorrectTime(val) {
    let now = new Date();
    let hour = ((now.getHours()+1 - now.getHours())*val)-4;
    let minute = now.getMinutes() - now.getMinutes();
    if (hour < 0){hour = hour+24} 
    if (hour <10) {return "0" + hour + ":0" + minute;}
    else {return hour + ":0" + minute;}
  }  
  
  function minTommss(minutes) {
        var sign = minutes < 0 ? "-" : "";
        var min = Math.floor(Math.abs(minutes));
        var sec = Math.floor((Math.abs(minutes) * 60) % 60);
        return sign + (min < 10 ? "0" : "") + min + ":" + (sec < 10 ? "0" : "") + sec;
      }

    
      
    const [sheetData, setData] = useState({});

    const { data, loading, error } = useGoogleSheets({
        apiKey: "AIzaSyDam7-qqRfOnNqb1-mgQ45W67XF2D68YFg",
        sheetId: "1-cFgZW4B2GKNNNmbPU7p6N5xNr_E4P50-rRX26SvYKA",
        //sheetsOptions: [{ id: 'Sheet1' }],
          });
    

  useEffect(()=>{

      if (!loading)
    {  
    
    
      
    SheetData =  Array.from(data); 
    const GarminJSON = JSON.stringify(SheetData[0]);
    const AveragesJSON = JSON.stringify(SheetData[2]);

    GarminData = Array.from(JSON.parse(GarminJSON).data);
    AverageData = Array.from(JSON.parse(AveragesJSON).data);
    
    AverageSteps = AverageData[0].StepsDay;
    AverageSleep = AverageData[0].AvgSleep;
    AverageStime = AverageData[0].AvgSleepTime;
    AverageStress = AverageData[0].AvgStress;
    AverageCalories = AverageData[0].Calories;
    AverageAlco = AverageData[0].AlcoholWeek;
    AverageWorkout = AverageData[0].WorkoutWeek;
    AverageMigrene = AverageData[0].MigreneTotal;
    AverageWater = AverageData[0].Water;
    AverageCoffee = AverageData[0].Coffee;
    AverageFree = AverageData[0].DaysWithoutMigrene;
    AverageActive = AverageData[0].ActiveDays;
    AverageMed = AverageData[0].Medicine;
    DayOfYear = AverageData[0].DayOfYear;
    SleepMin = AverageData[0].SleepMinutes;
    Streak = AverageData[0].MigreneStreak;

    

    

        const length = Object.keys(GarminData).length;
        if (Object.keys(seriesTime).length == 0)
        {
        
        for (var idz = 0; idz< length; idz++ )
          {
          seriesTime.push(GarminData[idz].Date);
          seriesSteps.push(GarminData[idz].Steps);
          seriesCalories.push(GarminData[idz].Calories);
          seriesFloors.push(GarminData[idz].Floors)
          seriesSleepScore.push(GarminData[idz].SleepScore)
          seriesStress.push(GarminData[idz].StressScore)
          seriesSleep.push((GarminData[idz].SleepDuration))
          seriesMigreneScore.push(GarminData[idz].MigreneScore)
          seriesMigreneMed.push(GarminData[idz].MigreneMedicine)
          seriesAlcohol.push(GarminData[idz].Alcohol)
          seriesCoffee.push(GarminData[idz].Coffee)
          seriesWater.push(GarminData[idz].Water)
          seriesWorkouts.push(GarminData[idz].WorkoutTime)
          seriesRuns.push(GarminData[idz].RunDistance)
          seriesWeight.push(GarminData[idz].Weight)
          seriesBedtime.push(GarminData[idz].Bedtime_neg)
          bedtime_raw.push(GarminData[idz].Bedtime)
          seriesWeekly.push(GarminData[idz].Sec_workout)                  
          }
          
        }
        if (Object.keys(seriesTime).length == length) {
          setReady(true)
          console.log("Data ready: " + isReady)}
    }
      })
       

        StepsOptions = {
            
            chart: {
            type:'line',
            height: 150,
            zoom: {
                enabled: true,    
            }

          },
          plotOptions: {
            
          },
          dataLabels: {
            enabled: false
          },
          noData: {
            text: 'Loading...'},
          stroke: {
            show: true,
            curve: 'smooth',
            width: 2,
            colors: ['transparent']
          },
          
          xaxis: {
            title: { 
              //text: "date",
              style: {
                color: "#000000"
              }
            },
            categories: seriesTime,
            tickAmount: undefined,
            type : 'datetime',
            labels: { format: 'dd MM' }
          },

          yaxis: [{
            title: {
              text: 'Steps',
              style: {
                    
                color: "#398565"
              }
              
            }},
            {
                opposite: true,
                axisTicks: {
                  show: true
                },
                axisBorder: {
                  show: true,
                  color: "#52BF91"
                },
                labels: {
                  style: {
                    colors: "#52BF91"
                  }
                },
                title: {
                  text: "Calories",
                  style: {
                    
                    color: "#52BF91"
                  }
                }
              }   
              ],
          
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " "
              }
            }
          },
        };     
          StepsSeries = [
            {
              name: "Steps",
              data: seriesSteps,
              type: "line",
              color : "#398565"             
            },
            {
                name: "Calories",
                data: seriesCalories,
                type: "area",
                color : "#d7ecd1"

              },
              {
                name: "Floors",
                data: seriesFloors,
                type: "line",
                color : "#52BF91"               
              }
          ]    

          
//------------------------ graph 2 ---------------------------------------------------

SleepOptions = {
            
    chart: {
    type: 'line',
    stacked : true,
    zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
    height: 150
  },

  plotOptions: {
    bar: {
        horizontal: false,
        columnWidth: '95%',
        endingShape: 'rounded'
      },  
  },
  dataLabels: {
    enabled: false
  },
  noData: {
    text: 'Loading...'},
  stroke: {
    show: true,
    curve: 'smooth',
    width: 2,
    colors: ['transparent']
  },
  
  xaxis: {
    title: { 
      //text: "date",
      style: {
        color: "#000000"
      }
    },
    categories: seriesTime,
    tickAmount: undefined,
    tickAmount: undefined,
            type : 'datetime',
            labels: { format: 'dd MM' }
  },

  yaxis: [{
    show: false,
    opposite: true,
    //type:'datetime',
    min: "18:00",
    max: "06:00",
    title: {
      text: 'Sleep time',
      //type: 'datetime', 
      style: {
            
        color: "#7d5ba6"
      }
      
    },
    
    labels: {  
      show: false,
        formatter: function(value) {
        return minTommss(value);
        }
            },

    tooltip: {
                y: {
                  formatter: function (val) {
                    return new Date(val)
                  }
                }
              },
    },
    {
        opposite: false,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#00b4ff"
        },
        labels: {
          style: {
            colors: "#00b4ff"
          }
        },
        title: {
          text: "Score",
          style: {
            
            color: "#00b4ff"
          }
        }
      }
         
      ],

  
  
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " "
      }
    }
  },
};     
  SleepSeries = [
    
    // {
    //   name: "Sleep time [h]",
    //   data: seriesSleep,
    //   type: 'column',
    //   fill: "gradient",
    //   color : "#006dfe",
    //   offsetY: 120              
    // },
    {
        name: "Sleep score [%]",
        data: seriesSleepScore,
        type: "line",
        fill: "gradient",
        color : "#00b4ff"               
      },
    {
        name: "Stress score [%]",
        data: seriesStress,
        type: "line",
        fill: "gradient",
        color : "#ff9f80"
                      
      }
      
  ]    


//------------------------ graph 3 ---------------------------------------------------





MigreneOptions = {
            
    chart: {
        type:'line',
        height: 150,
        zoom: {
            enabled: true,    
        }
  },

 

  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '45%',
      endingShape: 'rounded'
    },
  },
  dataLabels: {
    enabled: false
  },
  noData: {
    text: 'Loading...'},

  stroke: {
    show: true,
    curve: 'smooth',
    width: 2,
    colors: ['transparent']
  },
  
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 90, 100]
    }
  },

  xaxis: {
    title: { 
      //text: "date",
      style: {
        color: "#000000"
      }
    },
    categories: seriesTime,
    tickAmount: undefined,
    type : 'datetime',
    labels: { format: 'dd MM' }
  },

  yaxis: [
    {
      seriesName: 'Migrene medicine',
      axisTicks: {
        show: false,
        min: 0,
        max: 6
      },
      
      axisBorder: {
        show: true,
        color: "#644334"
      },
      labels: {
        style: {
          colors: "#644334"
        }
      },
        title: {
          text: "x 50mg",
          style: {
            color: "#644334"
          }
          
      }
      
    },

    {
      opposite: true,
      seriesName: 'Migrene intensity',
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
      },
      title: {
        text: "Migrene level"
      }
    }
  ],
  
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " "
      }
    }
  },
};     
  MigreneSeries = [
    
    {
        name: "Migrene medicine [50mg]",
        data: seriesMigreneMed,
        type: 'column',
        color : "#644334"               
      },
    {
        name: "Migrene intensity",
        data: seriesMigreneScore,     
        type: 'area',
        
        color : "#f8c583" 
                      
      }

      
  ]    


//------------------------ graph 4 ---------------------------------------------------





DrinkOptions = {
            
    chart: {
        type:'line',
        height: 150,
        zoom: {
            enabled: true,    
        },
        
        
    
    height: 150
       },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '95%',
      endingShape: 'rounded'
    },
  },
  dataLabels: {
    enabled: false
  },
  noData: {
    text: 'Loading...'},

  stroke: {
    show: true,
    curve: 'smooth',
    width: 2,
    colors: ['transparent']
  },

  fill: {
    type: "gradient",
    gradient: {
      type: "vertical",
    }
  },
  
  xaxis: {
    title: { 
      //text: "date",
      style: {
        color: "#000000"
      }
    },
    categories: seriesTime,
    tickAmount: undefined,
            type : 'datetime',
            labels: { format: 'dd MM' }
  },

  yaxis: [
    {
      seriesName: 'Alcohol units',
      show: true,
      opposite: true,
      axisTicks: {
        show: true,
      },
      
      axisBorder: {
        show: true,
        
      },
        title: {
          text: "Units"
      }
      
    },
    {
        opposite: false,
        seriesName: 'Coffee cups',
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: "Coffe"
        }
      },
      // {
      //   opposite: true,
      //   seriesName: 'Water [l]',
      //   axisTicks: {
      //     show: true
      //   },
      //   axisBorder: {
      //     show: true,
      //   },
      //   title: {
      //     text: "Water [l]"
      //   }
      // }

  ],
  
  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " "
      }
    }
  },
};     
  DrinkSeries = [
    {  
      name: "Alcohol [units]",
      data: seriesAlcohol,
      type: 'column',
      color : "#b31b6e",
                   
    },
    {  
        name: "Coffee [cups]",
        data: seriesCoffee,
        type: 'line',
        color : "#723c1e"             
      },
      {  
        name: "Water [liter]",
        data: seriesWater,
        type: 'area',
        color : "#91caea"             
      }
    

      
  ]    


//------------------------ graph 5 ---------------------------------------------------





RunOptions= {
            
    chart: {
    type:'bar',
        height: 150,
        zoom: {
            enabled: true,    
        },

    height: 150
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '65%',
      endingShape: 'rounded'
    },
  },
  noData: {
    text: 'Loading...'},

  dataLabels: {
    enabled: false
  },

    stroke: {
            show: true,
            curve: 'smooth',
            width: 2,
            colors: ['transparent']
          },
  
  
  xaxis: {
    title: { 
      //text: "date",
      style: {
        color: "#000000"
      }
    },
    categories: seriesTime,
    tickAmount: undefined,
    
            type : 'datetime',
            labels: { format: 'dd MM' }
  },

  yaxis: [

    
    
    {
    //type:'datetime',
    title: {
      text: 'Kilometers run',
      //type: 'datetime', 
      style: {
            
        color: "#7d5ba6"
      }
      
    },
    
    labels: {
        show: true,
        //formatter: function(value) {
        //return `${Math.round(value / 60)}`;
        //}
            },

    tooltip: {
                // y: {
                //   formatter: function (val) {
                //     return new Date(val)
                //   }
                // }
              },
    },
    {
        opposite: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#ffb700"
        },
        labels: {
          style: {
            colors: "#ffb700"
          }
        },
        title: {
          text: "Km weekly",
          style: {
            
            color: "#ffb700"
          }
        }
      }   
      ],

  fill: {
    opacity: 1
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " "
      }
    }
    
  },
};     
  RunSeries = [
    
    {
        name: "Run [km]",
        data: seriesRuns,
        type: 'column',
        color : "#398564"               
      },
     
    {
        name: "km/week",
        data: seriesWeekly,     
        type: 'area',
        curve: 'straight',
        fill: 'gradient',
        color : "#e8d53a" 
                      
      }

      
  ]    

//----------------------------- Graph 6 ------------------------------------------------





SleepTimeOptions = {
            
  chart: {
      type: 'rangeBar',
      stacked: true,
      height: 150,
      zoom: {
          enabled: true,    
      }
},



plotOptions: {
  bar: {
    isDumbbell: true,
    columnWidth: 8,
    dumbbellColors: [['#008FFB', '#00E396']]
  }
},
dataLabels: {
  enabled: false
},
noData: {
  text: 'Loading...'},


stroke: {
  show: true,
  curve: 'smooth',
  width: 2,
  colors: ['transparent']
},

fill: {
  type: "gradient",
  gradient: {
    shadeIntensity: 1,
    opacityFrom: 0.7,
    opacityTo: 0.9,
    stops: [0, 90, 100]
  }
},

xaxis: {
  title: { 
    //text: "date",
    style: {
      color: "#000000"
    }
  },
  categories: seriesTime,
  tickAmount: undefined,
  type : 'datetime',
  labels: { format: 'dd MM' }
},

yaxis: [
  {
    seriesName: 'Sleep hours',
    floating: false,
    //offsetY: -4,
    axisTicks: {
      show: true,
      //min: 0,
      //max: 12
    },
    axisTicks: {
      
      offsetY: -4,
    },
    labels: {
      "formatter": function (val) {
          
          return getCorrectTime(val)},
          format: 'dd MM'
      },
    axisBorder: {
      show: true,
      color: "#644334"
    },
    
      title: {
        text: "Sleep time",
        style: {
          color: "#644334"
        }
        
    }
    
  },


  // {
  //   opposite: true,
  //   seriesName: 'Sleep score',
  //   axisTicks: {
  //     show: false
  //   },
  //   axisBorder: {
  //     show: false,
  //   },
  //   title: {
  //     text: "score %"
  //   }
  // }
],

fill: {
  opacity: 1
},
tooltip: {
  enabledOnSeries: [0],
  y: {
    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
      console.log(
          "value:", value + '\n' +
          "series:", series + '\n' +
          "seriesIndex:", seriesIndex + '\n' +
          "dataPointIndex:", dataPointIndex + '\n' +
          "w:", w
      );
      console.log(w.globals.categoryLabels[value - 1]);
      return "Bedtime: " + bedtime_raw[dataPointIndex] + "\n" + "Sleep time: " + seriesSleep[dataPointIndex] + "\n" + "Sleep score: " + seriesSleepScore[dataPointIndex]
      }
  }
  }
};     
SleepTimeSeries = [
  
  {
      name: "Bedtime",
      data: seriesBedtime,
      type: 'column',
      stacked: true,
      color : "transparent" 
                    
    },
    {
      name: "Sleep time",
      data: seriesSleep,
      type: 'column',
      stacked: true,
      color : "#fe676e"

    },
    
    

    
]    

function LoadScreen(props) {
  if (!props.warn) {
    return(<div><
      br/>
      <center><h3>Loading graphs...</h3></center>
      <br/>
    </div>);
  }
else return (
    <div>
      <Chart
              options= {SleepTimeOptions}
              series={SleepTimeSeries}
              width="95%" 
              height={300}           
              
            />

            <Chart
              options= {SleepOptions}
              series={SleepSeries}
              width="95%" 
              height={300}           
              
            />

            <Chart
              options= {MigreneOptions}
              series={MigreneSeries}
              width="95%" 
              height={300}           
              
            />
            <Chart
              options= {StepsOptions}
              series={StepsSeries}
              width="96%" 
              height={300}           
              
            />

           <Chart
              options= {DrinkOptions}
              series={DrinkSeries}
              width="96%" 
              height={300}           
              
            />  
            
            <Chart
              options= {RunOptions}
              series={RunSeries}
              width="96%" 
              height={300}           
              
            /> 
    </div>
  );
}

    
return (
    <>
        

        <br/>
        <br/>
        <Row>
       
        <Container>
        <Row>
          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiBed/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageStime} <br/>sleep time
              <br/>
              <ProgressBar align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(SleepMin/480)} label={`${Math.round(100*(SleepMin/480))}% of 8h`}/>
              </Card.Body>
            </Card>
          </Col>

          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiNightSleep/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{Math.round(AverageSleep)}% <br/>sleep quality
              <br/>
              <ProgressBar  align="center" className="ml-2 mr-2 mb-2 mt-2" now={AverageSleep} label={`${AverageSleep}%`} />
              </Card.Body>
            </Card>
          </Col>

          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiRunningShoe/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{Math.round(AverageActive)} days <br/>active, YTD
              <br/>
              <ProgressBar variant="warning" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageActive/DayOfYear)} 
              label={`${(7*(AverageActive/DayOfYear)).toFixed(1)}/7`}/>
              </Card.Body>
            </Card>
          </Col>
          </Row>
          <br/>
          <Row>
          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiWalk/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{Math.round(AverageSteps)} <br/>steps/day
              <br/>
              <ProgressBar variant="success" align="center" className="ml-2 mr-2 mb-2 mt-2" now={AverageSteps*100/15000} 
              label={`${Math.round(AverageSteps*100/10000)}%`}/>
              </Card.Body>
            </Card>
          </Col>

          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiFlame/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{Math.round(AverageCalories)}<br/>calories/day
              <br/>
              <ProgressBar variant="danger" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageCalories/3500)}/>
              </Card.Body>
            </Card>
          </Col>
          
          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiStrong/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageWorkout} h<br/>workout/week
              <br/>
              <ProgressBar variant="danger" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100} />
              </Card.Body>
            </Card>
          </Col>
          
          
          </Row>
          <br/>
          <Row>
          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiWaterBottle/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageWater} L<br/>water/day
              <br/>
              <ProgressBar variant="info" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageWater/3)}
              label={`${AverageWater}/3`}/>
              </Card.Body>
            </Card>
          </Col>

          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiCoffeeCup/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageCoffee} cups<br/>coffee/day
              <br/>
              <ProgressBar variant="success" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageCoffee/5)}
              label={`${AverageCoffee}`}/>
              </Card.Body>
            </Card>
          </Col>
          
          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiWineGlass/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageAlco} units<br/>alcohol/week
              <br/>
              <ProgressBar variant="danger" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageAlco/5)}
              label={`${AverageAlco*15}ml`}/>
              </Card.Body>
            </Card>
          </Col>
          </Row>
          <br/>
          <Row>
          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiBrain/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageMigrene} days<br/>migrene, YTD
              <br/>
              <ProgressBar variant="warning" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageMigrene/DayOfYear)}
              label={`${(100*(AverageAlco/DayOfYear)).toFixed(1)}%`}/>
              </Card.Body>
            </Card>
          </Col>

          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiMedicines/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageMed*50}mg <br/>imigran, YTD
              <br/>
              <ProgressBar variant="success" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageMed*50/356)} label={`${AverageMed} x 50mg`}/>
              </Card.Body>
            </Card>
          </Col>

          <Col>  
            <Card >
              <Card.Title align="center" className="mt-2" ><GiCalendar/></Card.Title>
              <Card.Body align="center" className="ml-2 mr-2">{AverageFree} days ({Streak})<br/>migrene-free 
              <br/>
              <ProgressBar variant="warning" align="center" className="ml-2 mr-2 mb-2 mt-2" now={100*(AverageFree/DayOfYear)}/>
              </Card.Body>
            </Card>
          </Col>

          
        </Row>
      </Container>
        </Row>
       <br/>
       <LoadScreen warn={isReady} />
            
        


    </>


);
};

export default Graphs;
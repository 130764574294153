import React, { useEffect, useState } from "react"
import { Row, Col, Container, Image, Form} from "react-bootstrap"
import Card from "react-bootstrap/Card";

// 

var paceMinute = 0
var paceSecond = 0
var marathonTime
var Time10k
var halfMarathonTime
var distTime
var marathonResult
var halfMarathonResult
var result10k
var distanceEntry = 0
var resultDist
var kmH = 0

const PaceScreen = ({ match }) => {
  
  
  
  var totSecs


  function handleChange(evt) {
    const value = evt.target.value;
    
    setState({
      ...state,
      [evt.target.name]: value});

    console.log(evt.target.name, evt.target.value);
    

    if (evt.target.value < 0) {evt.target.value= 59}
    if (evt.target.value > 59) {evt.target.value= 0}

    // updating the pace values entered by user//
    if (evt.target.name === "mins") {paceMinute = evt.target.value}
    if (evt.target.name === "secs") {paceSecond = evt.target.value}
    if (evt.target.name === "dist") {distanceEntry = evt.target.value}


    //if (evt.target.name === "marathon") {paceSecond = evt.target.value}
    console.log(paceMinute,paceSecond);
    //calculate pace in seconds
    totSecs = (Number(paceMinute) * 60) + Number(paceSecond)
    
    
    // calculate total marathon time in seconds //
    marathonTime =  42.195 * totSecs
    
    // calculate total half-marathon time in seconds //
    halfMarathonTime = 21.1 * totSecs

    // calculate 10km total time //
    Time10k = 10 * totSecs

    //calculate entered distance total time
    distTime = distanceEntry * totSecs

    // calculate marathon time in DATE  format
    var dateM = new Date(null);
    dateM.setSeconds(marathonTime);
    marathonResult = dateM.toISOString().substr(11, 8);

    // calculate half-marathon time in DATE  format
    var dateHM = new Date(null);
    dateHM.setSeconds(halfMarathonTime); 
    halfMarathonResult = dateHM.toISOString().substr(11, 8);
    
    var date10k = new Date(null);
    date10k.setSeconds(Time10k);
    result10k = date10k.toISOString().substr(11, 8); 

    kmH = (3600/((Number(paceMinute) * 60) + Number(paceSecond)+0.001)).toPrecision(4)

    console.log(totSecs,marathonTime,marathonResult);
    
    // calculate time on entered distance
    var dateDist = new Date(null);
    dateDist.setSeconds(distTime);
    resultDist = dateDist.toISOString().substr(11, 8); 

    console.log(totSecs,distTime,resultDist);
    
  }  
  
  const [state, setState] = React.useState({
     
    })
       
  return (
    
    <> 
       
       <Container style={{background:"#DADAE0"}}>
<br/>
       <Card style={{background:"#F8FFCD"}}>
        <Card.Header as="h4"  ><p className="mCenter" style={{fontSize: 17 }}>Enter your avg. pace</p></Card.Header>
        <Card.Body>
        <Card.Text></Card.Text>
        <Form >
        <Form.Row>
          <Col className="center ml-2">
          
            <Form.Group  controlId="formPace">
            <Form.Label>min</Form.Label>
            <Form.Control  
             type="number"
             style={{ width: 70, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
             
             width="100px"
             value={state.minutes}
             name="mins"
             placeholder="0"
             onChange={handleChange}
            />
            </Form.Group>
          </Col> 
          
          <Col className="center mt-4"><p className="mCenter">:</p></Col>
          
          <Col className="center mr-2">
            <Form.Group controlId="formPace">
            <Form.Label>sec</Form.Label>
            <Form.Control  
             type="number"
             style={{ width: 70, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
             value={state.seconds}
             name="secs"
             placeholder="0"
             onChange={handleChange}
            />
            </Form.Group>
            </Col>
            </Form.Row>

            </Form>
        </Card.Body>
        </Card>    
    <br/>
        <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>Marathon time</p></Card.Header>
        <Card.Body>
        <Card.Text></Card.Text>
        <Form >
        <Form.Row className="center">
        <Form>
            <Form.Group  controlId="formBasicNumber">
            <Form.Label> </Form.Label>
            <Form.Control  
            type="text"
            style={{ width: 140, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
            value={marathonResult}
            name="marathon"
            placeholder="M-time"
            className="center"
            />
            </Form.Group>
            </Form>
            </Form.Row>

            </Form>
        </Card.Body>
        </Card> 
        <br/>
        <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>Half-marathon time</p></Card.Header>
        <Card.Body>
        <Card.Text></Card.Text>
        <Form >
        <Form.Row className="center">
        <Form>
            <Form.Group  controlId="formBasicNumber">
            <Form.Label> </Form.Label>
            <Form.Control  
            type="text"
            style={{ width: 140, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
            value={halfMarathonResult}
            name="halfMarathon"
            placeholder="HM-time"
            className="center"
            />
            </Form.Group>
            </Form>
            </Form.Row>

            </Form>
        </Card.Body>
        </Card> 
        <br/>
        <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>10km - time</p></Card.Header>
        <Card.Body>
        <Card.Text></Card.Text>
        <Form >
        <Form.Row className="center">
        <Form>
            <Form.Group  controlId="formBasicNumber">
            <Form.Label> </Form.Label>
            <Form.Control  
            type="text"
            style={{ width: 140, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
            value={result10k}
            name="d10k"
            placeholder="10km"
            className="center"
            />
            </Form.Group>
            </Form>
            </Form.Row>

            </Form>
        </Card.Body>
        </Card> 

      <br/>
      <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>Time on custom distance</p></Card.Header>
        <Card.Body>
        <Card.Text></Card.Text>
        <Form >
        <Form.Row className="center">
        <Col className="center ml-2">
           <Form.Group  controlId="formBasicNumber">
            <Form.Label>km</Form.Label>
            <Form.Control  
             type="number"
             style={{ width: 100, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
             
             width="180px"
             value={state.distance}
             step={0.1}
             //format={myFormat}
             precision={2}
             name="dist"
             placeholder="0"
             onChange={handleChange}
            />
           </Form.Group>
        </Col>
        <Col className="center ml-2">
        <Form.Group  controlId="formBasicNumber">
          <Form.Label>time</Form.Label>
            <Form.Control  
            type="text"
            style={{ width: 140, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
            value={resultDist}
            name="d10k"
            placeholder="time"
            className="center"
            />
            </Form.Group>
        </Col>
            
          </Form.Row>
        </Form>
        </Card.Body>
        </Card> 
<br/>
        <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>Pace in km/h</p></Card.Header>
        <Card.Body>
        <Card.Text></Card.Text>
        <Form >
        <Form.Row className="center">
        <Form>
            <Form.Group  controlId="formBasicNumber">
            <Form.Label> </Form.Label>
            <Form.Control  
            type="text"
            style={{ width: 140, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
            value={kmH}
            name="d10k"
            placeholder="0km/h"
            className="center"
            />
            </Form.Group>
            </Form>
            </Form.Row>

            </Form>
        </Card.Body>
        </Card> 
      <br/>
      <br/>
      <br/>
      <br/>
      </Container>
      
    </>
  )
}

export default PaceScreen
import React from "react";
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { useState, useEffect, useContext, createContext } from 'react'


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCCmnW2iks85p6rQqdYms52zBU2CGwGzLs",
  authDomain: "running-b9af9.firebaseapp.com",
  projectId: "running-b9af9",
  storageBucket: "running-b9af9.appspot.com",
  messagingSenderId: "848135796179",
  appId: "1:848135796179:web:4af48d6239022dea90ab26",
  measurementId: "G-CH6Z050Y28"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



export const firebaseApp = initializeApp(firebaseConfig)

export const AuthContext = createContext()



export const AuthContextProvider = props => {
  const [user, setUser] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), setUser, setError)
    return () => unsubscribe()
  }, [])
  return <AuthContext.Provider value={{ user, error }} {...props} />
}

export const useAuthState = () => {
  const auth = useContext(AuthContext)
  return { ...auth, isAuthenticated: auth.user != null }
}


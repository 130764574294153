import React, { useEffect, useState } from "react"
import { Row, Col, Card, Container, Alert, Image} from "react-bootstrap"
import { View, Text, ImageBackground } from "react-native"
import CountdownCircles from "./CountdownCircles"
import { useAuthState } from '../firebase'
import Countdown from "../components/Cuntdown"
import SheetPlanner from "../components/SheetPlanner"
import background from "../cover_.jpg"
import Papa from "papaparse";

// 
var activitiesJSON = []
var uke = ['monday',"tuesday","wednesday","thursday","saturday","sunday"]



const HomeScreen = ({ match }) => {

  const { user } = useAuthState()

//------------------- STRAVA ----------------------------------

  const [isLoading, setIsLoading] = useState(true)
  const [activities, setActivities] = useState({})
  const [data, setData] = useState({});
  

  

  

  //Strava Credentials
  let clientID = "69566";
  let clientSecret = "37735ff605c64560f3ac4d76c5494a87fca8b788";

  // refresh token and call address
  const refreshToken = "bf852301d012264d816c942114f53093163b9d0a";
  const callRefresh = `https://www.strava.com/oauth/token?client_id=${clientID}&client_secret=${clientSecret}&refresh_token=${refreshToken}&grant_type=refresh_token`

 // endpoint for read-all activities. temporary token is added in getActivities()
 const callActivities = `https://www.strava.com/api/v3/athlete/activities?access_token=`

 // Use refresh token to get current access token
 useEffect(() => {
   fetch(callRefresh, {
     method: 'POST'
   })
   .then(res => res.json())
   .then(result => getActivities(result.access_token))
 }, [callRefresh])

 // use current access token to call all activities
 function getActivities(access){
   console.log(callActivities + access)
     fetch(callActivities + access)
     .then(res => res.json())
     .then(data => setActivities(data), setIsLoading(prev => !prev))
     .catch(e => console.log(e))
     
     
 }

 function showActivities(){
   if(isLoading) return <>LOADING</>
   if(!isLoading) {
     console.log(activities[0])

    activitiesJSON = (activities)
    //console.log("const= " + activitiesJSON)
    

     return activities.length //JSON.stringify(activities)
   }
 }

//------------------- select activities ---------------------------

function activitiesByDate(date){

  var actToday = []

  for (var i=0; i < activities.length; i++) 
  {
    if (activities[i]['start_date_local'].split("T")[0] == date)
    {
      //console.log(activities[i]['name'])
      actToday.push(activities[i])
      console.log(actToday)
    }

  } 
 return actToday

}


//---------------------------------------------------------------
 
  const renderCards = (card, index) => {
    if (activities.length > 0) return (
      <Card style={{ width: "100%" }} key={index}>
      <Card.Body><Image width="20" src="./images/strava_badge.png"></Image><b>{activities[index]['start_date_local'].split('T')[0]}</b><br/>
      
      <Card.Link href={"https://www.strava.com/activities/"+ activities[index]['map']["id"].split("a")[1] } target="blank">{activities[index]['name']}</Card.Link>
      <br/>
      Distance: {((activities[index]['distance'])/1000).toFixed(2)} km<br/>  
      </Card.Body>
    </Card>
    );
  };

  const renderPlan = (index) => {
    return (
    <Card style={{ width: "100%" }} key={index}>
    <Card.Body>This is plan for {index}<br/>
    Description: How to run<br/>
    Suggested duration: 1h</Card.Body>
  </Card>);
  }

  
  const formated_Date = '2023-09-26T10:00:00Z';
  const date = new Date(formated_Date) 
  var yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
  var yestDate =  yesterday.getFullYear() + "-" + (yesterday.getMonth()+1) + "-" + yesterday.getDate()
  var today = new Date()
  var todDate = today.getFullYear() + "-" + (today.getMonth()+1) + "-" + today.getDate()
  var tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  var tomDate = tomorrow.getFullYear() + "-" + (tomorrow.getMonth()+1) + "-" + tomorrow.getDate()
  const currentDate = new Date();
    const year =
      currentDate.getMonth() === 11 && currentDate.getDate() > 23
        ? currentDate.getFullYear() + 1
        : currentDate.getFullYear();
        console.log(year)
       
  return (
    
    <> 
       <ImageBackground source="./images/Background.jpg" style={{width: '100%', height: '100%'}}>
   <View style={{position: 'absolute', top: 100, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center'}}>
     <Text> <Countdown date={`${year}-09-25T00:00:00`} /></Text>
   </View>
</ImageBackground>

       <Container style={{background:"#DADAE0" }}>
      <Image width="100%" src="./images/Background.jpg"></Image>
   
      
      <br/>
    
    <SheetPlanner/>

    <br/> 

    </Container>
      
    </>
  )
}

export default HomeScreen

import Header from "./components/Header"
import './App.css';
import React from "react"
import { BrowserRouter as Router, HashRouter, Route, Switch, Redirect } from "react-router-dom"


import HomeScreen from "./screens/HomeScreen"
import PaceScreen from "./screens/PaceScreen"
import StatsScreen from "./screens/StatsScreen"
import RiegelScreen from "./screens/RiegelScreen"
import GraphsScreen from "./screens/GraphsScreen"
import Login from "./components/Login.js"
import { AuthContextProvider, useAuthState } from './firebase'

// import Athlete from "./components/Athlete"

//---- login code -------

const AuthenticatedRoute = ({ component: C, ...props }) => {
  const { isAuthenticated } = useAuthState()
  console.log(`AuthenticatedRoute: ${isAuthenticated}`)
  return (
    <Route
      {...props}
      render={routeProps =>
        isAuthenticated ? <C {...routeProps} /> : <Redirect to="/Login" />
      }
    />
  )
}    

const UnauthenticatedRoute = ({ component: C, ...props }) => {
  const { isAuthenticated } = useAuthState()
  console.log(`UnauthenticatedRoute: ${isAuthenticated}`)
  return (
    <Route
      {...props}
      render={routeProps =>
        !isAuthenticated ? <C {...routeProps} /> : <Redirect to="/" />
      }
    />
  )
}

// ----- end of login code ----------


export default function App() {
  return (
    <AuthContextProvider>
    <HashRouter  basename={process.env.PUBLIC_URL}>
      <Header/>
        <Switch>
          
          <AuthenticatedRoute path="/" component={GraphsScreen} exact/>
          <UnauthenticatedRoute  path="/Login" component={Login} exact/>
          {/* <Route path={process.env.PUBLIC_URL + '/calculator'} component={PaceScreen}></Route> */}
          <AuthenticatedRoute path="/calculator" component={PaceScreen} exact/>
          {/* <Route path={process.env.PUBLIC_URL + '/stats'} component={StatsScreen}></Route> */}
          <AuthenticatedRoute path="/stats" component={StatsScreen} exact/>
          <AuthenticatedRoute path="/riegel" component={RiegelScreen} exact/>
          <AuthenticatedRoute path="/graph" component={HomeScreen} exact/>
          
        </Switch>
    </HashRouter>
    </AuthContextProvider>
  );
}




// OLD STUFF TO BE DELETED //


// function App() {
//   return (
          
//     <Router>
//       <Analytics id="G-RP5PVY57TS" debug>
//         <main className="mt-5 py-3">
//           <Container className="mt-2">
//           <Route path="/" component={HomeScreen} exact />
//           </Container>
//         </main>
//       </Analytics>
//     </Router>

//   );
// }

// export default App;
import React, { useEffect, useRef, useState } from "react"
import Papa from "papaparse";
import {Card, Image, Col, Row, Alert, Badge, Container, ProgressBar} from "react-bootstrap"
import { HiThumbUp } from "react-icons/hi";
import { GiRunningShoe, GiCycling, GiPathDistance, GiSpeedometer,  } from "react-icons/gi";
import {ScrollView} from "react-native"



const SheetPlanner = () => {


//------------------- STRAVA ----------------------------------

const [isLoading, setIsLoading] = useState(true)
const [activities, setActivities] = useState({})
const [stravaData, setStrava] = useState({});



  //Strava Credentials
let clientID = "69566";
let clientSecret = "37735ff605c64560f3ac4d76c5494a87fca8b788";

  // refresh token and call address
const refreshToken = "bf852301d012264d816c942114f53093163b9d0a";
const callRefresh = `https://www.strava.com/oauth/token?client_id=${clientID}&client_secret=${clientSecret}&refresh_token=${refreshToken}&grant_type=refresh_token`

 // endpoint for read-all activities. temporary token is added in getActivities()
const callActivities = `https://www.strava.com/api/v3/athlete/activities?access_token=`
const getAthleteStats = 'https://www.strava.com/api/v3/athletes/'+ clientID + '/stats?access_token='

 // Use refresh token to get current access token
 useEffect(() => {
   fetch(callRefresh, {
     method: 'POST'
   })
   .then(res => res.json())
   .then(result => getActivities(result.access_token))
 }, [callRefresh])

 // use current access token to call all activities
 function getActivities(access){
   console.log(callActivities + access)
     fetch(callActivities + access)
     .then(res => res.json())
     .then(data => setActivities(data), setIsLoading(prev => !prev))
     .catch(e => console.log(e))
     
     
 }

 function getStats(access){
  console.log(getAthleteStats + access)
  fetch(getAthleteStats + access)
  .then(res => res.json())
  
 }

 function showActivities(){
   if(isLoading) return <>LOADING</>
   if(!isLoading) {
     return activities.length //JSON.stringify(activities)
   }
 }

 function activitiesByDate(date){

  var actToday = []

  for (var i=0; i < activities.length; i++) 
  {
    if (activities[i]['start_date_local'].split("T")[0] == date)
    {
      //console.log(activities[i]['name'])
      actToday.push(activities[i])
      
    }

  } 
 return actToday

}

//-------------------Google sheets training callendar---------------------------


  const [data, setData] = useState({});
    Papa.parse("https://docs.google.com/spreadsheets/d/e/2PACX-1vTALuRXZQEa1dOIJfKAViX6kr3tHCHV8ke2xvz7qzvDv5IjXIzUxhSvjJDbKxwjGG4BhLqaAFTHCLBH/pub?output=csv", {
      download: true,
      header: true,
      complete: (results) => {
        setData(results.data);
      },
    });

    const scrollToToday = () => {window.scrollTo({ left: -500, behavior: "smooth"});}
    
    

    
    const AddStrava= (length) => {

      if (length > 0)  
      return ( <Image width="20" src="./images/strava.jpg"></Image>)
       else return null
   
    }

    const getLink= (url) => { return("https://www.strava.com/activities/" + url)}

    const getStravaIcon=(activity) => {if (activity=="Run") return <GiRunningShoe/>
  else if (activity=="Ride") return <GiCycling/> }
  
    const today = () => {
      var dateT= new Date()
      var month= (dateT.getMonth() < 10) ? "0" + (dateT.getMonth()+1) : dateT.getMonth()+1
      var day= (dateT.getDate() < 10) ? "0" + dateT.getDate() : dateT.getDate()
      
      var todDate = dateT.getFullYear() + "-" + month + "-" + day
      return todDate
    }

      
    //var todDate = today.getFullYear() + "-" + (today.getMonth()+1).toFixed(2) + "-" + new Date().getDate()
    
    const plan = Array.from(data);

    return (
<>

<br/>
<div onLoad={scrollToToday()}> 
<Container  style={{background:"#DADAE0", display: "inline-flex", overflowX: "scroll", overflowY: "hidden", 
WebkitOverflowScrolling: "touch", scrollBehavior: "smooth", touchAction: "auto"}}>




        {plan.map((data) => (
        
        <Card style= {data.Day == today() ? data.Day==today() ? { fontSize:"8", display: "inline-flex", minWidth: "65%", height:"170", marginBottom: '.3rem', marginLeft: '.7rem', marginRight: '.7rem', marginTop: '.3rem', background:"#EEEEEF" }:{ fontSize:"8", display: "inline-flex", minWidth: "65%", height:"170", marginBottom: '.3rem', marginLeft: '.7rem', marginRight: '.7rem', marginTop: '.3rem', borderWidth: '.2rem', borderColor: '#E36E4B'} : {display: "inline-flex", minWidth: "45%", height:"200", marginBottom: '.3rem', marginLeft: '.7rem', marginRight: '.7rem', marginTop: '.3rem'}} key={data.Day-3}>
        <Card.Body><Image width="20" src="./images/planner.png"></Image> <b>{data.Day.substring(5)}&nbsp;</b>{data.Day == today() && " Today" || data.Dayname}&nbsp;<Badge variant="secondary">w&nbsp;{data.week}</Badge>
        
        <Alert variant={"dark"}><b>Daily goal:<br/>&nbsp;</b><i>{data.Description}</i><Badge variant="warning">{data.km}&nbsp;km</Badge>
        &nbsp;<Badge variant={data.Type == "Interval" ? "danger" : data.Type == "Long run" ? "dark" : data.Type == "Rest" ? "light" : data.Type == "Tempo" ? "warning" :"primary"}>{data.Type}</Badge></Alert><br/>
      
        {/* <Card.Link href={activitiesByDate(data.Day).length > 0 &&  "https://www.strava.com/activities/"+ activitiesByDate(data.Day)[0]['map']["id"].split("a")[1] } 
        target="blank">{AddStrava(activitiesByDate(data.Day).length)} 
        {activitiesByDate(data.Day).length > 0 && " " + activitiesByDate(data.Day)[0]['name']}</Card.Link>
        <br/> */}
        {activitiesByDate(data.Day).length > 0 && activitiesByDate(data.Day).map((data1)=>(
          
          <div><Card.Link href={getLink(data1['map']["id"].split("a")[1])} target="blank">&nbsp;&nbsp;{AddStrava(1)}&nbsp;{data1['name']} </Card.Link>&nbsp;<HiThumbUp color="gray"/>&nbsp;{data1['kudos_count']}
          
          <br/>
          &nbsp;&nbsp;Distance: {((data1['distance'])/1000).toFixed(2) + "km"}<br/>&nbsp;&nbsp;Pace: {Math.floor(1/(data1['average_speed']/1000)/60)}:{(1/(data1['average_speed']/1000)%60).toFixed(0)}min/km<br/>&nbsp;&nbsp;Suffer score: {data1['suffer_score']}<br/>{data1['description']}
          <br/><br/>
          </div>
         
         //"Distance: " + ((data1['distance'])/1000).toFixed(2) + "km" +
         //"Intensity: " + data1['suffer_score']
         ))
        }
        
        </Card.Body>
        </Card>
        

        
          ))
          }
          </Container>
          </div>
          <br/><br/>

          <Alert variant="light"><center>This website shows my <i>daily</i> training plan in comparison with activities registered on strava.
          <br/>
          <br/><i>Powered by Strava API, GoogleSheets and Firebase</i></center> </Alert>
          </>
          
          
        
    );
  };
  export default SheetPlanner;
import React, { useEffect, useState } from "react"
import { Row, Col, Container, Image, Form, ListGroup} from "react-bootstrap"
import Card from "react-bootstrap/Card"


var Minutes = 0
var Seconds = 0
var Hours = 0
var halfMarathonTime
var marathonTime
var T1
var T2
var D1
var D2
var distanceEntry = 0.1
var marathonResult
var halfResult


const RiegelScreen = ({ match }) => {
  
  
  
  var totSecs


  function handleChange(evt) {
    const value = evt.target.value;
    
    setState({
      ...state,
      [evt.target.name]: value});

    console.log(evt.target.name, evt.target.value);
    

    if (evt.target.name !== "dist")
    
    {
    if (evt.target.value < 0) {evt.target.value= 59}
    if (evt.target.value > 59) {evt.target.value= 0}
    } 
    else
    {
    if (evt.target.value < 0.1) {
      evt.target.value = 0.1}
       
    }

    // updating the pace values entered by user//
    if (evt.target.name === "mins") {Minutes = evt.target.value}
    if (evt.target.name === "secs") {Seconds = evt.target.value}
    if (evt.target.name === "hour") {Hours = evt.target.value}
    if (evt.target.name === "dist") {distanceEntry = evt.target.value}
    console.log(distanceEntry);

    // //if (evt.target.name === "marathon") {paceSecond = evt.target.value}
    // console.log(paceMinute,paceSecond);
    
    // //calculate total sec
    totSecs = (Number(Hours) * 3600) + (Number(Minutes) * 60) + Number(Seconds)
    
    
    // // calculate total marathon time in seconds //
    D1 =  (Math.pow(42.19/distanceEntry, 1.06)) * totSecs
    console.log(D1);

    var dateM = new Date(null);
    dateM.setSeconds(D1);
    marathonResult = dateM.toISOString().substr(11, 8);

    // calculate total marathon time in seconds //
    D2 =  (Math.pow(21.1/distanceEntry, 1.06)) * totSecs
    var dateHM = new Date(null);
    dateHM.setSeconds(D2);
    halfResult = dateHM.toISOString().substr(11, 8);
    
    // console.log(totSecs,distanceEntry,marathonTime);
  }  
  
  const [state, setState] = React.useState({
     
    })
       
  return (
    
    <> 
       
       <Container style={{background:"#DADAE0"}}>
<br/>
      <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>The Peter Riegel formula</p></Card.Header>
        <Card.Body>
        <Card.Text ></Card.Text>
        <Image width="70%" src="./images/riegel.png"></Image>
        </Card.Body>
      </Card>
      <br/>

       <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>Enter distance and time</p></Card.Header>
        <Card.Body>
        <Card.Text></Card.Text>
        <Form >
        <Form.Row>
          <Col className="center ml-2">
          
            <Form.Group  controlId="formPace">
            <Form.Label>Distance</Form.Label>
            <Form.Control  
             type="number"
             style={{ width: 120, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
             width="100px"
             value={state.distance}
             step={0.1}
             name="dist"
             placeholder="0.1"
             onChange={handleChange}
            />
            </Form.Group>
          </Col> 
          
          
          
          <Col className="center mr-2">
            <Form.Group controlId="formPace">
            <Form.Label>Hours</Form.Label>
            <Form.Control  
             type="number"
             style={{ width: 90, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
             value={state.hours}
             name="hour"
             placeholder="0"
             onChange={handleChange}
             className="center mr-2"
            />
            </Form.Group>
            <Form.Group controlId="formPace">
            <Form.Label>Minutes</Form.Label>
            <Form.Control  
             type="number"
             style={{ width: 90, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
             value={state.minutes}
             name="mins"
             placeholder="0"
             onChange={handleChange}
             className="center mr-2"
            />
            </Form.Group>
            <Form.Group controlId="formPace">
            <Form.Label>Seconds</Form.Label>
            <Form.Control  
             type="number"
             style={{ width: 90, fontSize: 22, borderColor: "#ffffff", outline: "none", borderRadius: "8px" }}
             value={state.seconds}
             name="secs"
             placeholder="0"
             onChange={handleChange}
             className="center mr-2"
            />
            </Form.Group>
            </Col>
            </Form.Row>

            </Form>
        </Card.Body>
        </Card>
        <br/> 
        <Card>
        <Card.Header as="h4"><p className="mCenter" style={{fontSize: 17}}>Predicted times:</p></Card.Header>
        <Card.Body>
        <Card.Text ></Card.Text>
        <ListGroup>
  <ListGroup.Item>Marathon: <b>{marathonResult}</b></ListGroup.Item>
  <ListGroup.Item>Half-marathon: <b>{halfResult}</b></ListGroup.Item>
  
</ListGroup>
        </Card.Body>
      </Card>
         
    

      <br/>
      <br/>
      <br/>
      <br/>
      </Container>
      
    </>
  )
}

export default RiegelScreen
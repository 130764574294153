import React, { useState, runCallback, useCallback, useEffect, Component } from "react" 
import { Row, Container, Form, Button, Table, Alert, Dropdown, DropdownButton, Card } from "react-bootstrap"
import Chart from "react-apexcharts"
import Papa from "papaparse";
import Graphs from "../components/Graphs"


    
const GraphsScreen = ({ match }) => {


    return (
      
        <> 
        <Graphs/>
        </>
    )


}




export default GraphsScreen